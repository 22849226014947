.menu-item {

    &-container {
        padding: 16px;
        background-color: $light-grey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        border-radius: 8px;
        cursor: pointer;
        color: $black;

        &.small {
            padding: 10px;

            .menu-item-icon {
                min-width: 28px;
                max-width: 28px;
                height: 28px;
            }

            .menu-item-title {
                font-size: 180%;
                padding-left: 12px;
            }
        }
    }

    &-content {
        display: flex;
        align-items: center;
    }

    &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;

        img {
            width: 100%;
        }

        i {
            font-size: 200%;
        }
    }

    &-title {
        font-size: 230%;
        padding-left: 18px;
    }

    &-arrow {
        font-size: 230%;
     
        color: $black;

    
    }

    &-left {
        display: flex;
        flex-direction: column;

        &-title {
            font-size: 200%;
            margin-bottom: 8px;
        }

        &-date {
            font-size: 160%;
        }
    }

    &-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 10vw;

        &-amount {
            font-size: 200%;
            font-weight: 700;
        }

        &-date {
            font-size: 160%;
        }
    }
}



.direct-debit-container .menu-item , 
.standing-order-container .menu-item
  {

    &-container {
        padding: 16px 18px 2px 16px;

    }

    &-arrow {
        margin-bottom: 11.5px;
    }
}

