.autocomplete-dropdown-container {

    .suggestion-item {
        color: white;
    }

    .auto-complete-icon {
        color: $black;
    }
}

.auto-complete-icon {
    color: $white;
}

.date-picker {
    .icon-wrapper {
        color: $white;
    }
}

.form-field {

    label {
        color: white
    }

    input {
        color: white;
    }

    & .generic-input {
        color: white;

        &.select-list {
            padding: 0;
        }

        & > .selected-box {

            & > .placeholder {
                color: white;
            }
        }

        & > .option-selection {
            .option {
                background: $dark-box-background!important;
            }
        }
    }
}

.switch-content {
    color: $dark-sub-text;
}

.sm-input {

    &.postcode {

        .location-search-input {
            padding-left: 42px !important;
        }

        .sm-input-prepend-icon {
            top: 50%;
            transform: translateY(-75%);
            padding-left: 12px;
        }
    }

    .sm-input-append-icon {
        i {
            color: white;
        }
    }
}

.styles_react-code-input-container__tpiKG {
    input {
        background-color: $dark-box-background;
    }
}