.transactions-container {

    h2 {
        color: $white !important;
    }

    .transaction-container {
        .transaction-toggle, .content-inner {
            background-color: $dark-box-background;

            i {
                color: $dark-sub-text;
            }

            p {
                color: $dark-sub-text;

                &.green {
                    color: $confirmButton;
                }
            }

            strong {
                color: $dark-sub-text !important;
            }
        }
    }
}