
.adverts-container {
    max-width: 840px;
    margin: 0 auto;

    img {
        border-radius: 20px; 
        @include box-shadow;
    }
  
    .adverts-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        position: relative;

        .item {
            width: 100%;
            height: 140px;
        }

        .adverts-arrow {
            background-color: $black;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            cursor: pointer;
            @include vertical-center;
    
            .caret {
                font-size: 360%;
                color: white;
            }
    
            &.left {
                left: -70px;
    
                .caret {
                    margin-left: -5px;
                }
            }
    
            &.right {
                right: -70px;
    
                .caret {
                    margin-right: -5px;
                }
            }
    
            &.disabled {
                opacity: 0.2;
                cursor: not-allowed;
            }
        }
    } 

    @media only screen and (max-width: 960px) {
        .adverts-grid {
            & > .item {
                height: 100px;
            }
        }
    }
}