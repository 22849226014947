h1, h2, h3, h4, h5, h6, a {
  color: white;
}

.sm-typography {
  color: $light-text !important;
}

.sm-login-container, .help-statement {
  background-color: $pageBodyDark;
}

.login-info-circle {
  border-color: white;

  i {
    color: white;
  }
}

.footer-links {
  a{
    color: white;
  }
}