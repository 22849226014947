@mixin devices($size) {
    @if $size == sm {
      @media (min-width: 600px) {
        @content;
      }
    } @else if $size == md {
      @media (min-width: 960px) {
        @content;
      }
    } @else if $size == tablet {
      @media (min-width: 1024px) {
        @content;
      }
    } @else if $size == lg {
      @media (min-width: 1280px) {
        @content;
      }
    } @else if $size == xl {
      @media (min-width: 1440px) {
        @content;
      }
    } @else if $size == xxl {
      @media (min-width: 1660px) {
        @content;
      }
    } @else if $size == xxxxl {
      @media (min-width: 1920px) {
        @content;
      }
    } @else {
      @media (min-width: $width) {
        @content;
      }
    }
  }
  