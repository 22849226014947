.transferwise-container {

    .info-slug {
        color: $dark-sub-text !important;
    }

    .terms-links-wrapper {
    
        p {
            color: $dark-sub-text;
        }
    }

    .transferwise-error {
        p {
            color: $white;
        }
    }
}

// initial quote
.international-quote-calculator-container {
    .source-container, .recipient-container {
        border-color: $white;

        .amount-box {
            .amount-input-container {
                border-color: $white;

                input, div {
                    color: white;
                }

                .input-header {
                    background-color: $black;
                    color: $white;
                }
            }
        }

        .currency-area {
            i {
                color: $white;
            }
        }
    }

    .currency-code {
        color: $dark-sub-text;
    }

    .information-container {
        .information-box {
            border-color: $white;
            background-color: $black;
            color: $white;
        }

        .grid-1 {
            border-right-color: $white;
        }
    }

    .arrival-time {
        color: $dark-sub-text;
    }
}

.review-and-send-container {
    color: $dark-sub-text;
}

.international-payment-status-container {
    color: $dark-sub-text;
}