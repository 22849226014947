.account-overview-container {

    p {
        @include dm-sans-bold;
        margin-bottom: 10px;
        font-size: 290%;
        text-align: center;

        &.account-number {
            @include dm-sans-regular;
            font-size: 180%;
        }

        &.amount-label {
            @include dm-sans-regular;
            font-size: 180%;
            margin: 15px 0 0;
        }

        &.amount {
            @include dm-sans-regular;
            color: #fff;
            font-size: 500%;
        }
    }
}

.account-select {
    border-radius: 7px;
    padding: 8px;
    
    &.multi-accounts {
        cursor: pointer;
        &:hover {
            transition: background-color 0.1s ease-in;
            background-color: darken($black, 8%);;
        }
    }

    .account-details-wrapper {
        display: flex;
        
        .icon {
            font-size: 290%;
            margin-left: 15px;
    
        }
    }

    .account-number {
        margin: 0;
    }
}