.arrow-button {
    background-color: $black;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;
    @include vertical-center;

    .caret {
      margin-top: 2px;
      font-size: 360%;
      color: white;
    }

    &.left {
      left: -70px;

      .caret {
        margin-left: -5px;
      }
    }

    &.right {
      right: -70px;

      .caret {
        margin-right: -5px;
      }
    }

    &.disabled {
      background-color: lighten($black, 60%);
      pointer-events: none;
    }

    &:hover {
      opacity: 0.9;
    }
}