.international-payment-status-container {
    max-width: 540px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .status-icon {

        i {
            font-size: 600%;
            color: green;
        }
    }

    .status-message {
        margin: 24px 0;
        text-align: center;
        font-size: 200%;
        font-weight: 600;
    }
}