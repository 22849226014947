//login
.app-store-badges-wrapper {
    p {
        margin-bottom: 64px;

        font-size: 200%;
        text-align: center;
    }

    &.footer {
        display: none;

        @include devices(tablet) {
            display: block;
        }
    }

    &.mobile {
        display: block;

        @include devices(tablet) {
            display: none;
        }
    }

    .app-store-badges {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include devices(tablet) {
            flex-direction: row;
            justify-content: center;
        }

        a {
            margin: 0 0 8px 8px;
            cursor: pointer;

            img {
                width: 150px;
            }

            &:last-child {
                margin-right: 0;
            }

            @include devices(tablet) {
                margin: 0 24px 0 0;
            }
        }
    }
}


.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        width: 100%;
        display: block;
    }

    .field-link-container {
        text-align: center;

        @include devices(tablet) {
            text-align: right;
        }
    }
}

.tell-gateway-container {
    padding: 0 40px;
    position: relative;
    height: 100vh;
    margin-bottom: 0;

    .data-check-error {
        align-items: flex-start;
    }

    .overlay-image {
        position: absolute;
        right: 0;
        width: 60%;
        height: 100%;
        &.dark-mode {
            z-index: -1;
        }
    }

    .logo {
        width: 200px;
        margin-bottom: 50px;
    }

    h1 {
        font-size: 25px;
        margin-bottom: 10px;
    }

    p {
        font-size: 20px;
    }

    .tell-gateway-buttons {
        display: flex;
        button {
            width: 10%;
            font-size: 20px;
            background-color: $green;
            margin-right: 10px;
            margin-top: 0;
        }
    }

    .tell-gateway-inner-container {
        display: flex;
        justify-content: space-between;

        .left-container {
            width: 40%;

            .header-description {
                margin-bottom: 0;
                line-height: 28px;
            }

            .tell-gateway-accounts {
                margin-top: 50px;
                .account-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 30px;
                    padding: 20px 0;
                    border-top: .5px solid $medium-grey;
                }
            }

            
        }

        .right-container {
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            p {
                margin-bottom: 10px;
            }
            h1 {
                margin-bottom: 20px;
            }
            p,
            h1 {
                color: $white;
            }
            .consent-permissions-title {
                margin-bottom: 0;
            }
            .consent-details {
                strong {
                    font-size: 18px;
                }
            }
            .consent-permissions {
                display: flex;
                flex-wrap: wrap;
                span {
                    font-size: 18px;
                    text-decoration: underline;
                    color: $white;
                    margin-right: 5px;
                    margin-top: 10px;
                }
            }

            .payment-details {
                margin-top: 40px;
                border-radius: 20px;
                padding: 20px;
                p,
                h1 {
                    color: $black;
                }
            }
        }
    }

    @media (max-width: 900px) {
        .overlay-image {
            display: none;
        }
        .tell-gateway-inner-container {
            justify-content: center;
            flex-direction: column;
            margin-bottom: 30px;
            .left-container,
            .right-container {
                width: 100%;
            }
            
            .right-container {
                margin-top: 10px;
                p,
                h1 {
                    color: $black;
                }
                .consent-permissions {
                    margin-bottom: 20px;
                    span {
                        color: $black;
                    }
                }
                .payment-details {
                    padding: 0;
                    border-radius: unset;
                    &.border-box {
                        padding-left: 0;
                    }
                }
            }
        }
        .tell-gateway-buttons {
            button {
                width: 20%;
            }
        }

    }

    @media (max-width: 550px) {
        padding: 0 10px;
        h1 {
            font-size: 20px;
        }

        p {
            font-size: 16px;
        }

        .logo {
            margin: 20px 0;
        }
        .tell-gateway-inner-container {
           .left-container {
                .tell-gateway-accounts {
                    margin-top: 20px;
                }
            }
            .right-container {
                h1 {
                    margin-bottom: 10px;
                }
            }
        }
        .tell-gateway-buttons {
            padding-bottom: 60px;
            button {
                width: 50%;
            }
        }
    }
}

.activation-container {
    text-align: center;
}

.password-container {
    text-align: center;

    .password-form {
        text-align: left;
    }

    .meter-container {

        progress {
            width: 100%;
        }
    }
}

.security-questions-container {

    .security-questions-form {

        .question-title {
            font-size: 150%;
            font-weight: 600;
            margin-bottom: 16px;
        }
    }
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i {
            font-size: 250%;
        }

        p {
            margin-top: 24px;
            font-size: 180%;
        }
    }
}

.enter-passcode-container {
    text-align: center;
}

.closeBtn {
    background-color: unset;
    text-decoration: underline;
    padding: 5px;
    color: #333;
    margin-top: 0;
    font-size: 15px;
}