.transactions-container {
  .transaction-toggle-button {
    display: block;
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 15px;
    margin: 20px auto;
    color: $black;
    font-size: 14px;
  }
  .transaction-show-text {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
  }
  .transactions-scroll-container {
    margin: 35px 0;
   
    .monthly-transaction-container {
      margin-bottom: 25px;

      h2 {
        @include dm-sans-medium;
        color: $black;
        font-size: 200%;
        margin-bottom: 20px;
      }

  
    }

    input[type='checkbox'] {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
      z-index: 1213;
      cursor: pointer;
    }
  
    .transaction-container {
      margin-bottom: 10px;

      &:hover {
        .transaction-toggle {
          background: $light-grey-hover;
        }

        .content-inner {
          background: $light-grey-hover;
        }
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    .transaction-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      background: $light-grey;
      cursor: pointer;
      border-radius: 8px;
      transition: 200ms background ease-in-out;

      &.no-action {
        cursor: auto;
      }
  
      .transaction-img {
        padding: 6px;
        background-color: #2E7EC2;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 36px;
          height: 36px;
        }
  
        img {
          width: 100%;
          max-width: 36px;
        }
      }
  
      .transaction-amount {
        font-weight: bold;

        &.green {
          color: $confirmButton;
        }
      }
  
      .transaction-left {
        display: flex;
        align-items: center;

        i {
          font-size: 180%;
          color: $grey;
          margin-right: 8px;
        }
      }
  
      .transaction-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 15vw;
      }
      
      p {
        color: black;
        font-size: 130%;
        @include dm-sans-medium;
      }
    }
    
    .collapsible-content {
      max-height: 0px;
      overflow: hidden;
      transition: max-height .25s ease-in-out;
  
      .content-inner {
        background-color: $light-grey;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 5px 15px 15px;
        transition: 200ms background ease-in-out, 200ms border-bottom-right-radius ease-in-out, 200ms border-bottom-left-radius ease-in-out;
  
        p {
          font-size: 130%;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
  
        strong {
          @include dm-sans-bold;
        }
      }
    }

    
    .toggle:checked + .transaction-toggle + .collapsible-content {
      max-height: 100vh;
      transition: max-height 0.3s ease-in-out;
    }
    
    .toggle:checked + .transaction-toggle {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }


  .amount {
    font-size: 180%;
  }
}