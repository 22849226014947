.payments-container {

    .button-container {
        display: flex;
        margin-bottom: 48px;

        button {
            @include last-child {
                margin-left: 32px;
            }
        }
    }

    .add-cash-steps {
        margin-top: 15px;
        line-height: 1.4;

        p {
            margin: 5px 0;
            font-size: 180%;
            
            &:last-child {
                margin-top: 20px;
            }
        }
    }

    .add-cash-faqs-container {
        margin-top: 50px;
        line-height: 1.4;
        
        .text {
            font-size: 180%;
        }

        strong {
            font-weight: 600;
            font-size: 110%;
        }
    }
}

.payments-menu-container {
    margin-top: 40px;
}

.send-payments-container {
    
    form {
        margin: 0 auto;
        width: 80%;

        .form-field {
            width: 80%;
            margin: 0 auto 30px;
        }
    }

    .button-container {
        display: flex;
        margin-top: 48px;

        button {

            @include last-child {
                margin-left: 32px;
            }
        }
    }
}

.standing-order-container, .direct-debit-container {
    
    .loading-spinner {
        @include dm-sans-medium;
        margin-bottom: 10px;
        font-size: 290%;
        text-align: center;
    }

    .active-container {
        margin-bottom: 32px;
    }

    .cancelled-container {
        margin-bottom: 32px;
    }

    button {
        width: auto;
        padding-left: 64px;
        padding-right: 64px;
    }
}

.view-standing-order-container, .view-direct-debit-container {
    margin-bottom: 32px;

    .view-item {
        border-bottom: 1px solid $grey;
        padding: 16px 0;
        padding-left: 16px;

        h2 {
            font-size: 200%;
            margin-bottom: 0
        }

        h3 {
            font-size: 165%;
            margin-top: 16px;
            margin-bottom: 0;
        }
    }
} 


.payment-status-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .status-icon {

        i {
            font-size: 600%;
            color: $confirmButton;
        }

        &.error {

            i {
                font-size: 600%;
                color: $red; 
            }
        }
    }

    .status-message {
        margin: 24px 0;
        text-align: center;
        font-size: 200%;
        font-weight: 600;
    }
}

.confirmation-of-payee-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 600px;

    .confirmation-of-payee-header {
        text-align: center;
        margin-bottom: 30px;

        img {
            width: 55px;
            margin-bottom: 20px;
        }

        .title {
            font-size: 30px;
            font-weight: bold;
        }
    }

    .text {
        font-size: 18px;
        line-height: 30px;
    }

    .confirmation-of-payee-detailContainer {
        width: 100%;

        .detail-header-text {
            font-size: 18px;
            text-transform: uppercase;
            margin-top: 30px;
            margin-bottom: 8px;
        }

        .confirmation-of-payee-detail-box {
            border: 1px solid $medium-grey;
            border-radius: 10px;
            background-color: $light-grey;
            padding: 10px;
            box-shadow: inset 0 0 5px $medium-grey;
            position: relative;

            .button-apply-container {
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 15px;

                i {
                    color: $blue;
                }

                .apply-button {
                    background-color: transparent;
                    color: $blue;
                    font-size: 15px;
                    width: unset;
                    padding: 0;
                    margin: 0;
                }
            }

            .account-sort-code {
                display: flex;
                align-items: stretch;

                .account-sort-code-line {
                    width: 2px;
                    background-color: $black;
                    margin: 0 10px;
                }
            }

            p {
                font-size: 18px;
                margin-bottom: 20px;
                font-weight: 500;

                &.error {
                    text-align: left;
                    color: $red;
                    font-weight: bold;
                }
                &.sort-code, &.account-number {
                    margin-bottom: 0;
                }
                &.account-number {
                    margin-right: 5px;
                }
            }
            .payee-title {
                font-weight: bold;
                margin-bottom: 5px;
                font-size: 16px;
            }
        }
    }
    .confirmation-of-payee-buttonContainer {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;

        button {
            width: 100%;

            &.change-button {
                background-color: $meter-fair;
            }
            &.proceed-button {
                background-color: $red;
            }
        }
    }
}

.force-create-payee {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 600px;

    .header-container {
        text-align: center;
        img {
            margin-bottom: 15px;
        }
        .red-text {
            color: $red;

            &.header {
                font-size: 35px;
                font-weight: bold;
            }
            &.sub-header {
                font-size: 20px;
                line-height: 30px;
                font-weight: 500;
            }
        }

        .question-text {
            font-size: 25px;
            margin-top: 24px;
            margin-bottom: 10px;
            font-weight: bold;
        }
    }

    .button-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
        button {
            width: 100%;
        }
        .change-button {
            background-color: $white;
            color: $black;
            border: 1px solid $black;
        }
    }

    .checkbox-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 20px;
        padding: 0 20px;

        .text {
            font-size: 18px;
            margin-left: 5px;
        }
        .proceed-button {
            width: 100%;
            background-color: $red;
            color: $white;
        }
    }
}