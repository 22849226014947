.more-container {

    .more-title {
        margin-bottom: 35px;
    }
}

.more-account-details-container {
    .consent-management-inner {
        margin-top: 20px;
        &>h1 {
            font-size: 25px;
            border-bottom: 1px solid black;
            padding-bottom: 10px;
        }
        .consent-button-delete {
            margin-top: 20px;
            width: 20%;
        }
    }
    .loadable-content-data-check {
        font-size: 20px;
        text-align: center;
    }
    .account-details {
        margin-bottom: 48px;

        @include last-child {
            margin-bottom: 0;
        }
        
        h1 {
            font-size: 250%;
        }

        p {
            font-size: 180%;
            color: $black;
        }

        &-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;

            h1 {
                font-size: 250%;
            }

            .currency {
                display: flex;
                flex-direction: column;
                align-items: center;
                
                .currency-flag {
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    background-position: center center;
                    margin-bottom: 6px;
                }

                p {
                    font-size: 120%;
                }
            }
        }
        
        &.consent-item {
            list-style-type: disc;
            font-size: 17px;
            margin: 10px 20px;

            li {
                margin-top: 10px;
            }

            &.dark-mode {
                color: $white;
            }
        }
    }

    .knowledge-section {
        background-color: white;
        padding: 24px;
        border-radius: 8px;

        h3 {
            font-size: 200%;
        }

        ul {

            li {
                margin-bottom: 8px;
                font-size: 160%;

                @include last-child {
                    margin-bottom: 0;
                }

                img {
                    width: 8px;
                    height: 8px;
                    margin-right: 16px;
                    top: -1px;
                }
            }
        }
    }
}

.more-personal-details-container {

    .personal-details {
        margin-bottom: 48px;

        @include last-child {
            margin-bottom: 0;
        }
        
        h1 {
            font-size: 250%;
            font-weight: 700;
        }

        p {
            font-size: 180%;
            color: $black;
        }

        &.spacing-bottom {

            p {
                margin-bottom: 8px;
            }
        }
    }
}

.sm-input .postcode .disabled {
    opacity: 0.1;
}

.choose-address-mode-button {
    margin-top: 0;
}

.more-settings-container {

    .settings-container {

        .setting {
            margin-bottom: 32px;
        }
    }
}

.switch-list-container{
    .switch {
        margin-bottom: 32px;
    }
}

.add-funds-container {

    .description {
        font-size: 170%;
        line-height: 1.4;
        margin-bottom: 18px;
    }
}

.funds-container {

    p {
        font-size: 170%;
        line-height: 1.4;
    }
}

.more-refer-a-friend-container {

    .refer-a-friend-container {

        .title {
            font-size: 250%;
        }

        .subtitle {
            font-size: 230%;
            font-style: italic;
            color: $black;
            font-weight: 700;
        }

        .headline {
            font-size: 230%;
            color: $black;
            font-weight: 700;
        }

        .text {
            font-size: 180%;
            line-height: 1.4;
        }
        strong {
            font-weight: 600;
            font-size: 110%;
        }
    }

    .refer-a-friend-faqs-container {
        margin-top: 50px;
        line-height: 1.4;
        
        .headline {
            font-size: 230%;
            color: $black;
            font-weight: 700;
        }

        .text {
            font-size: 180%;
        }

        strong {
            font-weight: 600;
            font-size: 110%;
        }
    }
}

.more-cashback-rewards-container {

    .cashback-rewards-container {

        .title {
            font-size: 250%;
        }

        .subtitle {
            font-size: 230%;
            font-style: italic;
            color: $blue;
            font-weight: 700;
        }

        .headline {
            font-size: 230%;
            color: $blue;
            font-weight: 700;
        }

        .text {
            font-size: 180%;
            line-height: 1.4;

            &:last-child {
                margin-top: 32px;
            }
        }
    }
}